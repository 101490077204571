import React, {useEffect, useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/layout";
import HomepageLeadItems from "../components/homepage/homepage-lead-items";
import HomepageItem from "../components/homepage/homepage-item";

const IndexPage = () => {
    const data = useStaticQuery(query);
    const mainArticles = data.mainArticles.edges;
    mainArticles.sort((a,b) => (a.node.main_page > b.node.main_page) ? 1 : ((b.node.main_page > a.node.main_page) ? -1 : 0))
    const bottomArticlesList = data.bottomArticles.edges;

    const [ active, setActive ] = useState(0);
    const [ hasMore, setMore ] = useState(bottomArticlesList.length > 3);
    const [ bottomArticles, addArticles ] = useState([...bottomArticlesList.slice(0, 3)]);

    const loadArticles = () => {
        const currentLength = bottomArticles.length;
        const more = currentLength < bottomArticlesList.length;
        const nextBottomArticles = more ? bottomArticlesList.slice(currentLength, currentLength + 3) : [];
        setMore(more);
        addArticles([...bottomArticles, ...nextBottomArticles]);
    };

    const handleScroll = () => {
        if ( !hasMore ) return;
        if ( window && ( window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 ) ){
            loadArticles()
        }
    };

    const handleTouchEnd = (e) => {
        if (e.target.localName !== "a") {
            handleScroll();
        }
    };

    useEffect(() => {
        window && window.addEventListener('touchend', handleTouchEnd, {passive: true});
        window && window.addEventListener('scroll', handleScroll, {passive: true});
        window && window.addEventListener('resize', handleScroll, {passive: true});
        return () => {
            window && window.removeEventListener('touchend', handleTouchEnd);
            window && window.removeEventListener('scroll', handleScroll);
            window && window.removeEventListener('resize', handleScroll);
        };
    }, [bottomArticles, hasMore]);

    return (
        <Layout seo={data.strapiHomepage.seo}>
            <div className="page-wrapper homepage">
                <div className="page-container">
                    <HomepageLeadItems articles={mainArticles} />
                    <hr />
                    <div className="row">
                        {bottomArticles.map((article, i) => {
                            return (
                                <HomepageItem index={i + 1} className={i + 1 === active ? "active" : "normal"} onMouseOver={e => setActive(i+1)}
                                              article={article}
                                              key={`article__bottom__${article.node.slug}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    mainArticles: allStrapiArticle(filter: { main_page: { regex: "/.*Head.*/"}}) {
      edges {
        node {
          published_at
          strapiId
          slug
          title
          main_page
          description
          category {
            name
          }
          image {
            publicURL
            childImageSharp {
              fixed(width: 800, height: 500) {
                src
              }
            }
          }
          imageFormats {
            id
            formatSize
            url
            width
          }
        }
      }
    }
    bottomArticles: allStrapiArticle(limit: 21, sort: {fields: published_at, order: DESC}, filter: { main_page: { regex: "/null/"}}) {
      edges {
        node {
          published_at
          strapiId
          slug
          main_page
          title
          description
          category {
            name
          }
          image {
            publicURL
            childImageSharp {
              fixed(width: 800, height: 500) {
                src
              }
            }
          }
          imageFormats {
            id
            formatSize
            url
            width
          }
        }
      }
    }
  }
`;

export default IndexPage;
