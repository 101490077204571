import React from "react";
import HomepageLeadItem from "./homepage-lead-item";

const HomepageLeadItems = ({ articles }) => {

    let leadItems = {
        lead1: null,
        lead2: null,
        lead3: null,
    };
    articles.forEach(article => {
        if (article.node.main_page === 'Head_1') {
            leadItems.lead1 = article;
        } else if (article.node.main_page === 'Head_2') {
            leadItems.lead2 = article;
        } else if (article.node.main_page === 'Head_3') {
            leadItems.lead3 = article;
        } else {
            return leadItems;
        }
    });

    return (
        <div className="row">
            <div className="col-xl-8 col-lg-12 left-lead-article">
                <div>
                    {leadItems && leadItems.lead1 !== null ?
                    <HomepageLeadItem
                        article={leadItems.lead1}
                        key={`article__main__${leadItems.lead1.node.slug}`}
                    /> : ''}
                </div>
            </div>
            <div className="col-xl-4 col-lg-12 right-lead-articles">
                <div className="leadArticleItem leadArticleItem-top">
                    {leadItems && leadItems.lead2 !== null ?
                        <HomepageLeadItem
                            article={leadItems.lead2}
                            key={`article__main__${leadItems.lead2.node.slug}`}
                        /> : ''}
                </div>
                <div className="leadArticleItem">
                    {leadItems && leadItems.lead3 !== null ?
                        <HomepageLeadItem
                            article={leadItems.lead3}
                            key={`article__main__${leadItems.lead3.node.slug}`}
                        /> : ''}
                </div>
            </div>
        </div>
    );
};

export default HomepageLeadItems;
