import React from "react";
import {Link} from "gatsby";
import ResponsiveArticleImage from "../responsive-article-image";
import CategoryNameComponent from "../presentation-components/category-name.component";

const HomepageItem = ({ article }) => {

    return (
        <div className="col-xl-4 col-lg-6 col-md-12 d-flex">
            <Link className="d-flex" to={`/article/${article.node.slug}`}>
                <div  className="article">
                    <div className="image-wrap">
                        <ResponsiveArticleImage imageFormats={article.node.imageFormats} alt={article.node.title}></ResponsiveArticleImage>
                        <div className="gradient" />
                        <CategoryNameComponent category={article.node.category}></CategoryNameComponent>
                    </div>
                    <h2 className="title">
                        {article.node.title}
                    </h2>
                        <p className="article-beginning">
                            {article.node.description}
                        </p>

                </div>

            </Link>
        </div>
    );
};

export default HomepageItem;
