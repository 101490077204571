import React from "react";
import {Link} from "gatsby";
import ResponsiveArticleImage from "../responsive-article-image";
import CategoryNameComponent from "../presentation-components/category-name.component";

const HomepageLeadItem = ({ article }) => {

    return (
        <Link to={`/article/${article.node.slug}`} className="lead-article">
                <div className="image-wrap">
                    <ResponsiveArticleImage imageFormats={article.node.imageFormats} alt={article.node.title}></ResponsiveArticleImage>
                    <div className="gradient" />
                    <hr />
                    <CategoryNameComponent category={article.node.category}></CategoryNameComponent>
                    <h2  className="title"> {article.node.title}</h2>
                </div>

        </Link>
    );
};

export default HomepageLeadItem;
